/* eslint-disable */
/* eslint-disable no-self-assign */
/* eslint-disable template-curly-spacing */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import intlTelInput from 'libs/intl-tel-input';
import Swiper from 'libs/swiper';
import 'libs/select2';

export default class Steps {
    swipeInit = () => {
        this.swiper = new Swiper(this.$swiperContainer, {
            direction: 'horizontal',
            simulateTouch: false,
            loop: false,
            shortSwipes: false,
            longSwipes: false,
            allowTouchMove: false,
            keyboard: {
                enabled: false,
                onlyInViewport: false,
            },
            onlyExternal: true,
            autoHeight: true,
            initialSlide: this.activeStep - 1,
            on: {
                slideChange: () => {

                },
                init: () => {

                },
            },
        });
    };

    constructor() {
        // Containers Cache
        this.$formsContainer = document.querySelector('.form--main--container');
        this.$step1Container = this.$formsContainer.querySelector('.step--one--container');
        this.$step2Container = this.$formsContainer.querySelector('.step--two--container');
        this.$form1 = this.$step1Container.querySelector('form');
        this.$form2 = this.$step2Container.querySelector('form');
        this.$stepsProgressContainer = this.$formsContainer.querySelector('.step--progress--list');
        this.$errorContainer = this.$formsContainer.querySelector('.step--error--container');
        this.$paymentFailErrorContainer = this.$formsContainer.querySelector('#payment_failure_sec');
        this.$closePaymentFailMessage = this.$formsContainer.querySelector('#closePaymentFailMessage');
        this.$phone = this.$formsContainer.querySelector('.tel--input');
        this.$swiperContainer = this.$formsContainer.querySelector('.swiper-container');
        this.$activeStepContainer = this.$stepsProgressContainer.querySelector('li.active');
        this.$currencyInput = document.querySelector("input[data-type='currency']");
        this.$elements = this.$stepsProgressContainer.getElementsByTagName('li');
        this.htmlNbody = $('body, html');
        this.telephone = null;

        // Variables
        this.token = this.$formsContainer.getAttribute('data-token');
        this.activeStep = this.$activeStepContainer.getAttribute('data-step-id');
        this.swiper = null;

        // Initialize Components
        this.initSelect();
        this.clearErrors();
        this.initTel();
        this.swipeInit();
        this.setdisableTabInput();


        // Events
        $(this.$stepsProgressContainer).on('click', 'li', this.slideInit.bind(this));
        $(this.$step1Container).on('click', '.cta--button', this.validateStep1.bind(this));
        $(this.$step2Container).on('click', '.cta--button', this.validateStep2.bind(this));
        $(this.$closePaymentFailMessage).on('click', this.clearPaymentFail);
        $(this.$currencyInput).on('blur', this.appendZeroes);
    }


    loaderHide() {

    }

    loaderShow() {

    }

    setdisableTabInput()
    {
       $('#amount').keydown(function (objEvent) {
            if (objEvent.keyCode == 9) {  //tab pressed
                objEvent.preventDefault(); // stops its action
            }
        });
    }

    validateStep1() {
        this.clearErrors();
        this.loaderShow();
        $.ajax({
            method: 'POST',
            url: `${CCM_BASE_URL}${CCM_REL}/index.php/api/form/step1`,
            data: `${$(this.$form1).serialize()}&ccm_token=${this.token}`,
            success: (res) => {
                if (res.success === false) {
                    for (let i = 0; i < res.errors.length; i++) {
                        $(this.$errorContainer).append(`<li>${res.errors[i]}</li>`);
                    }
                    this.htmlNbody.animate({scrollTop: 0,});
                } else {
                    this.activeStep = 2;
                    this.swiper.slideTo(1);
                    this.setHistory();
                    this.toggleActiveClass();
                }
            },
            error: (err) => {
                console.log('API Offline');
            },
        });
        this.loaderHide();
    }

    validateStep2() {
        this.clearErrors();
        this.loaderShow();
        //Add dial code to form data
        let dialCode = '';
        if (this.telephone.getSelectedCountryData()) {
            dialCode = this.telephone.getSelectedCountryData().dialCode;
            $('#dial_code').val(dialCode);
        }

        $.ajax({
            method: 'POST',
            url: `${CCM_BASE_URL}${CCM_REL}/index.php/api/form/step2`,
            data: `${$(this.$form2).serialize()}&ccm_token=${this.token}`,
            success: (res) => {
                if (res.success === false) {
                    for (let i = 0; i < res.errors.length; i++) {
                        $(this.$errorContainer).append(`<li>${res.errors[i]}</li>`);
                    }
                    this.htmlNbody.animate({scrollTop: 0,});
                } else {
                    window.location.href = `${CCM_BASE_URL}${CCM_REL}/index.php/api/form/step3`;
                }
            },
            error: (err) => {
                console.log('API Offline');
            },
        });
        this.loaderHide();
    }

    initTel() {
        this.telephone = intlTelInput(this.$phone, {
            autoHideDialCode: false,
            initialCountry: 'ae',
            separateDialCode: true,
        });
    }

    clearErrors() {
        this.$errorContainer.innerHTML = '';
    }

    clearPaymentFail = () => {
        $(this.$paymentFailErrorContainer).hide();
    }

    initSelect() {
        $('.selectField').select2();
    }

    appendZeroes = (ev) => {
        let n = ev.target.value;
        if (n.length) {
            n = n.replace(/,/g, '');
            n = parseFloat(n).toFixed(2);
            let withCommas = Number(n).toLocaleString('en');

            if (withCommas.indexOf('.') === -1) {
                withCommas += '.00';
            }
            if (isNaN(n)) {
                $(this.$currencyInput).val('');
            } else {
                $(this.$currencyInput).val(withCommas);
            }
        }
    }

    // eslint-disable-next-line consistent-return
    slideInit(event) {

        this.clearErrors();
        const $el = $(event.currentTarget);
        const step = $el.data('step-id');
        const index = step - 1;

        if (this.activeStep == 4) {
            return false;
        }

        if (step === 3 || step === 4) {
            return false;
        }

        if (step === 2) {
            this.validateStep1();
            return false;
        }

        this.activeStep = 1;
        this.swiper.slideTo(index);
        this.setHistory();
        this.toggleActiveClass();
    }

    setHistory = () => {
        if (window.history.pushState) {
            window.history.pushState('', '',
                `?step=${this.activeStep}`);
        }
    };

    toggleActiveClass = () => {
        const activeStep = this.activeStep;
        $(this.$elements).each((index, elem) => {
            if ((index + 1) === activeStep) {
                $(elem).addClass('active');
            } else {
                $(elem).removeClass('active');
            }
        });
    };
}
